import * as React from "react";
import {Helmet} from "react-helmet";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";


const TomorrowTalk = ({data}) => {
  data.allMarkdownRemark.edges.sort(function(a, b) { return Number(b.node.frontmatter.episode) - Number(a.node.frontmatter.episode) });
  const {edges : podcasts} = data.allMarkdownRemark;
  const seo = {
    frontmatter: {
      seo: {
        title: "Tomorrow Talk",
        description: "Conversations with visionaries about what tomorrow holds",
      },
    },
    fields: {
      slug: "/tomorrow-talk",
    },
  };
  return (
    <React.Fragment >
      <SEO {...seo} />
      <Helmet bodyAttributes={{ class: "index-podcast on-dark" }} />   
      <section className="section podcast-index-section">
        <div className="radial-background"></div>
        <div className="container">
          <h6 className="has-text-centered">Our Podcast</h6>
          <h4 className="has-text-centered tagline">Conversations with visionaries about what tomorrow holds</h4>
          <h1 className="has-text-centered">Tomorrow Talk</h1>
          <div className="content feed">
            {podcasts.map(podcast => {
              const info = podcast.node.frontmatter;
              return (
                <div className="columns column is-justify-content-center feed-item">
                  <div className="column is-4">
                    <span className="episode-number">{info.episode}</span>
                  </div>
                  <div className="column is-6">
                    <h4 className="episode-title">{info.title}</h4>
                    <p className="episode-description">{info.description}</p>
                    <ul className="source-link-list is-flex">
                      {info.sourcelinks.map(link => {
                        return (
                          <li>
                            <a href={link.sourcelink}>{link.linktext}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}


export default TomorrowTalk;

export const QueryPodcast = graphql`
  query TomorrowTalkQuery {
    allMarkdownRemark(
      sort: {order: DESC, fields: frontmatter___episode}
      filter: {frontmatter: {filterKey: {eq: "tomorrowtalk"}}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            episode
            sourcelinks {
              linktext
              sourcelink
            }
          }
        }
      }
    }
  }
      `;
